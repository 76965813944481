import type { Route } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { ReRouteComponent } from './shared/components/re-route/re-route.component';

export const appRoutes: Route[] = [
  {
    path: '',
    component: ReRouteComponent,
  },
  {
    path: '',
    canActivate: [authGuard],
    loadComponent: () => import('./shared/components/with-navigation/with-navigation.component').then((m) => m.WithNavigationComponent),
    children: [
      {
        path: 'tenants',
        children: [
          {
            path: '',
            loadComponent: () => import('./features/tenant-list/tenant-list.component').then((m) => m.TenantListComponent),
          },
          {
            path: 'create',
            loadComponent: () => import('./features/tenant-create/tenant-create.component').then((m) => m.TenantCreateComponent),
          },
          {
            path: ':id/update',
            loadComponent: () => import('./features/tenant-update/tenant-update.component').then((m) => m.TenantUpdateComponent),
          },
        ],
      },
    ],
  },
  {
    path: 'callback',
    loadComponent: () => import('./shared/components/callback/callback.component').then((m) => m.CallbackComponent),
  },
  {
    path: 'error/:error-code',
    loadComponent: () => import('./features/error-page/error-page.component').then((m) => m.ErrorPageComponent),
  },
  {
    path: '**',
    redirectTo: '/error/404',
  },
];
